import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import PrivacyPolicy from "../components/privacy-policy"


const PrivacyPolicyPage = () => (
    <Layout>
      <Seo title="About" />
      <PrivacyPolicy />
    </Layout>
  )
  
  export default PrivacyPolicyPage